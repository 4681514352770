import React, { useState } from 'react';
import { getImageUrl } from 'takeshape-routing';

import Link from './link';
import folx from '../assets/images/coffeeFolx.png';
import heartCup from '../assets/images/ct-heartCup.png';

const SponsorsIntro = ({ className = '' }) => (
  <section className={`fundIntro f aic mb3 ${className}`}>
    <div className="mastheadCopy asc f fdc">
      <h1>
        Our Sponsors
      </h1>
      <p>
        ComeTogether's first cohort of grant recipients was supported by over $8500 in donations from coffee lovers around the world, and the generosity of the sponsor companies below.
        <br />
        Thank you so much for your support.
      </p>
      <Link
        to="/fund"
        className="btn btn--lg btn--ghost hover-cw ass mt15"
      >
        Support The Fund
      </Link>
      {/* <Link
        to="/pages/sponsors"
      >
        Sponsorship Info →
      </Link> */}
    </div>
    <div className="mastheadAsset">
      <img alt="The ComeTogether Fund" src={heartCup} />
    </div>
  </section>
);

export default SponsorsIntro;
