import React, { useState } from 'react';
import SponsorCard from './SponsorCard';

import routes from '../routes';
import Link from './link';

const SponsorsList = ({
  sponsors,
  headerCopy = 'Sponsors',
  showCta = false,
  ctaCopy = 'See More',
  h2 = false,
  className = '',
  ...props
}) => {
  let Header = () => (
    <h1>{headerCopy}</h1>
  );
  if (h2 === true) {
    Header = () => (
      <h2>{headerCopy}</h2>
    );
  }
  return (
    <section className={`${className}`}>
      <Header />
      <section className="card-list">
        {sponsors.items.map((sponsor, i) => <SponsorCard sponsor={sponsor} key={sponsor.sponsorName} />)}
      </section>
      {showCta && (
        <Link
          to="/sponsors"
          className="btn btn--lg"
        >
          {ctaCopy}
        </Link>
      )}
    </section>
  );
};

export default SponsorsList;
