import React, { useState } from 'react';
import { getImageUrl } from 'takeshape-routing';
import slugify from 'slugify';
import { friendlyDate } from '../lib/datetime';

import routes from '../routes';

import Link from './link';

const SponsorCard = ({ sponsor }) => {
  const sponsorSlug = slugify(sponsor.sponsorName.toLowerCase());
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  let donationTotal = numberWithCommas(sponsor.donationTotal);
  let cardImg = 'https://placekitten.com/1400/800';
  if (sponsor.sponsorLogo !== null) {
    cardImg = getImageUrl(sponsor?.sponsorLogo?.path, {
      h: 800,
      w: 1400,
      fit: 'cover',
    });
  }
  return (
    <article className="sponsor" id={sponsorSlug}>
      {sponsor.sponsorLogo !== null ? (
        <img
          className="card__image sponsors contain"
          src={cardImg}
          alt={sponsor.sponsorName}
        />
      ) : (
        <img
          className="card__image contain"
          src="https://placekitten.com/600/400"
          alt={sponsor.sponsorName}
        />
      )}
      <div className="card__wrapper">
        <div className="card__copy">
          <div className="card__nameLocation">
            <h2 className="sponsor__name mb0">{sponsor.sponsorName}</h2>
            <h3 className="mb1">Total Donated: ${donationTotal}</h3>
          </div>
          <div className="card__body">
            <div dangerouslySetInnerHTML={{ __html: sponsor.sponsorDetailsLongform }} />
          </div>
        </div>
        {/* <Link
          to={routes.sponsor(sponsor.title)}
          className="card__cta"
        >
          Read More
          {' '}
          →
        </Link> */}
      </div>
      {/* Uncomment if/when sponsor pages are enabled. */}
      {/* <Link to={routes.sponsor(sponsor.title)} className="sponsor abs fill" /> */}
    </article>
  );
};

export default SponsorCard;
