import React, { useState } from 'react';
import { graphql } from 'gatsby';
import SponsorsIntro from '../components/SponsorsIntro';
import SponsorList from '../components/SponsorsList';
import Layout from '../layouts/default';

const SponsorsPage = ({ data }) => (
  <Layout>
    <SponsorsIntro />
    {data.takeshape.allSponsors.total <= 0 ? (
      <h2>Whoops, no sponsors</h2>
    ) : <></>}
    {data.takeshape.tier1Sponsors.total > 0 ? (
      <SponsorList sponsors={data.takeshape.tier1Sponsors} headerCopy=" " />
    ) : <></> }
    {data.takeshape.tier2Sponsors.total > 0 ? (
      <SponsorList sponsors={data.takeshape.tier2Sponsors} headerCopy="Silver Sponsors" />
    ) : <></> }
    {data.takeshape.tier3Sponsors.total > 0 ? (
      <SponsorList sponsors={data.takeshape.tier3Sponsors} headerCopy="Bronze Sponsors" />
    ) : <></> }
  </Layout>
);

export default SponsorsPage;

export const query = graphql`
  query {
    takeshape {
      allSponsors: getSponsorsList(
        sort: { order: "DESC", field: "donationTotal" }
      ){
        items {
          sponsorName
          cardBio
          donationTotal
          sponsorUrl
          sponsorDetailsLongform: sponsorDetailsLongformHtml
          sponsorLogo {
            path
          }
        }
      }
      tier1Sponsors: getSponsorsList(
        where: { sponsorLevel: { match: "first" } }
        sort: { order: "DESC", field: "donationTotal" }
      ) {
        total
        items {
          sponsorName
          cardBio
          donationTotal
          sponsorUrl
          sponsorDetailsLongform: sponsorDetailsLongformHtml
          sponsorLogo {
            path
          }
        }
      }
      tier2Sponsors: getSponsorsList(
        where: { sponsorLevel: { match: "second" } }
      ) {
        total
        items {
          sponsorName
          cardBio
          donationTotal
          sponsorUrl
          sponsorDetailsLongform
          sponsorLogo {
            path
          }
        }
      }
      tier3Sponsors: getSponsorsList(
        where: { sponsorLevel: { match: "third" } }
      ) {
        total
        items {
          sponsorName
          cardBio
          donationTotal
          sponsorUrl
          sponsorDetailsLongform
          sponsorLogo {
            path
          }
        }
      }
    }
  }
`;
